<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'Training',
  data: () => ({
    
  })
}
</script>